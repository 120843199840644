<template>
    <div class="center" :class="{'attached': $router.currentRoute.name !== 'login1'}">
        <div class="article internal" v-if="isMainPage">
            <div class="platforms">
                <div class="platform platform__block">
                    <h2>"Climate CRON" is the official payment instrument of the "International Fund for Saving the Aral Sea".</h2>
                    <p>
                        <img src="@/resources/images/aral_logo.png" class="aral-logo">
                    </p>
                    <p>
                        On January 20, 2023, an agreement was signed on strategic partnership between the
                        <a href="http://kazaral.org/en/agreement-between-climate-global-control-trading-llc-and-the-the-executive-board-of-the-international-fund-for-saving-the-aral-sea-in-the-republic-of-kazakhstan/" target="_blank">
                            The Executive Board of the International Fund for saving the Aral Sea in the Republic of Kazakhstan"
                        </a> "Climate CRON", which will be used as a payment instrument for calculating works related to climate regulation and water resources management in the region.
                    </p>
                    <p>
                        This partnership is a continuation of the strategy of monetization of the global water resources market and trust in the recognized "Climate CRON" tool among common customers.
                        The use of "Climate CRON" will allow not only to attract performers and innovative technologies to carry out work to improve the climate in the Aral Sea region, but also to receive additional investments using the resources of private partners.
                        </p>
                    
                    <div class="platform__block-additional">
                        <p class="pdf-download">
                            <a href="https://climatecron.com/articles/artificial-management-of-climate-on-the-territory-of-the-aral-sea">
                                <img src="@/resources/images/pdf-download-2617.png" class="pdf-logo">
                                <span>Presentation</span>
                            </a>
                        </p>
                        <p class="meta">
                            <a style="color: white" href="http://kazaral.org/en/agreement-between-climate-global-control-trading-llc-and-the-the-executive-board-of-the-international-fund-for-saving-the-aral-sea-in-the-republic-of-kazakhstan/" target="_blank">www.kazaral.org</a>
                            <span>January 20, 2023</span><span>Almaty, Kazakhstan</span>
                        </p>
                    </div>
                </div>
                <div class="platform" v-if="false">
                    <div class="chart__wrapper narrow">
                        <h2>Total purchased Climate Cron: <span style="font-weight: bold;">225,000</span></h2>
                        <!-- <charts :chartdata="chartData" :options="chartOptions" :height="chartsHeight"/> -->
                    </div>
                    <article-widget :limit="5" :header="`News`" :showCategory="false" :category="1" :page="1" :classModifier="'wide'"></article-widget>                     
                    <div class="chart__wrapper narrow">                        
                        <h2>Photo reports</h2>
                        <div class="widget-images">                   
                            <agile :slidesToShow="1" :fade="true" :navButtons="true" :dots="false">                                
                                <div class="feature">
                                    <img src="https://climatecron.com/images/news/24032021.jpeg"/>
                                </div>
                                <div class="feature">
                                    <img src="https://climatecron.com/images/galleries/3/21.jpeg"/>
                                </div>
                                <div class="feature">
                                    <img src="https://climatecron.com/images/galleries/3/13.jpeg"/>
                                </div>
                                <div class="feature">
                                    <img src="https://climatecron.com/images/galleries/3/14.jpeg"/>
                                </div>
                                <div class="feature">
                                    <img src="https://climatecron.com/images/galleries/3/25.jpeg"/>
                                </div>
                            </agile>
                        </div>     
                    </div>
                </div>
                <div class="platform" v-if="false">
                    <div class="chart__wrapper narrow">
                        <h2>Analytic forecast</h2>
                        <div id="anchartdiv" ref="anchartdiv"></div>
                    </div>
                    <div class="chart__wrapper wide">
                        <h2>Meeting with Chairman of Senate, Pakistan</h2>
                        <div class="gallery">
                            <enlargeable-image src="/images/galleries/1/4.jpeg" src_large="/images/galleries/1/4.jpeg" />
                            <enlargeable-image src="/images/galleries/1/1.jpeg" src_large="/images/galleries/1/1.jpeg" />
                            <enlargeable-image src="/images/galleries/1/2.jpeg" src_large="/images/galleries/1/2.jpeg" />
                        </div>
                        <h2>Meeting with Chamber of Commerce, Pakistan</h2>
                        <div class="gallery">
                            <enlargeable-image src="/images/galleries/2/1.jpeg" src_large="/images/galleries/2/1.jpeg" />
                            <enlargeable-image src="/images/galleries/2/3.jpeg" src_large="/images/galleries/2/3.jpeg" />
                            <enlargeable-image src="/images/galleries/2/4.jpeg" src_large="/images/galleries/2/4.jpeg" />
                        </div>
                    </div>      
                    <article-widget :limit="4" :header="`Expert analytics`" :showCategory="false" :category="2" :page="1" v-if="false"></article-widget>   
                </div>
            </div>
        </div>
        <div v-if="isMainPage"
            class="presale">
            <div class="presale__cron">
                <h2>Offer for private investors</h2>
                <ul>
                    <li>Steady increasing price. Last rise from $27,000 to $30,000 on Septempber 27, 2024;</li> 
                    <li>Trade on our website without commission and fees;</li> 
                    <li>Available for Pay Pal, Visa, Mastercard transactions;</li> 
                    <li>Repurchase guarantee (in accordance with the contract after 365 days, at a fixed price) only for investors who bought through our website;</li> 
                    <li>Security of the transaction from fraudsters.</li> 
                </ul>
                <router-link :to="{ name: 'presale'}" class="presale__preorder">Order</router-link>
            </div>
        </div>
        
        <div class="article internal" v-if="isMainPage">
            <div class="platforms">
                <div class="platform">
                    <p style="padding: 0 20px;">The information in this website has not been reviewed by, passed on or submitted to any U.S. federal, state, or foreign agency, securities regulator or self-regulatory organization in any jurisdiction.
                    <br/><br/>The Climate Cron tokens have not been and will not be registered under the Securities Act of 1933, as amended (the “Securities Act”), or any other law or regulation governing the offering, sale or exchange of securities in the United States or any other jurisdiction. The offering of the Climate Cron tokens will be made (1) inside the United States to “accredited investors” (as defined in Section 501 of the Securities Act) who are U.S. persons (as defined in Section 902 of Regulation S under the Securities Act) in reliance on Regulation D under the Securities Act and (2) outside the United States to non-U.S. persons in reliance on Regulation S. Subject to certain limited exceptions, Climate Cron token purchasers will be required to maintain their Climate Cron tokens until the first anniversary of the issuance of the Climate Cron tokens.</p>
                </div>
            </div>
        </div>
        <div class="water-info" v-if="false && isMainPage">
            <h2>13% OF THE WORLD’S POPULATION DOES NOT HAVE ACCESS TO DRINKING WATER</h2>
            <div class="lack-water">
                <img src="@/resources/images/world_map_water_access_mohamed_el_baz.png">
            </div>
        </div>
        <router-view/>
        <div class="main__separator" v-if="isMainPage">          
            <div class="cron">
                <div class="cron__features">
                    <div class="custom-announce" style="color: #fff;">
                        <p style="font-size: 15px; line-height: 22px;">The Climate Cron token offers a solution to this problem by combining funding for programs to prevent climate risks and support countries experiencing a water and food crisis. This allows investors to guarantee stability and profitability and protect their investments in the new global climate security market.</p>
                        <p style="font-size: 15px; line-height: 22px;">Climate Cron also seeks to monetize the water market and ensure climate stability. This will help create new opportunities for both financing and earning money on environmental protection and combating climate risks.</p>
                        <p style="font-size: 15px; line-height: 22px;">In 2023, the global economy will experience difficulties, and a new business process model will become necessary. Climate Cron offers a new indicator of the reliability and stability of states - climate security. Countries that are actively engaged in preventing climate risks and supporting vulnerable regions in the fight against water and food crises will be considered more reliable partners for investors.</p>
                        <p style="font-size: 15px; line-height: 22px;">The Climate Cron token offers an innovative solution for a new time, taking into account the interests of all participants in the global climate security market and ensuring stability and protection of investments.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="how-to-start" v-if="isMainPage">
            <div class="arrow-left" @click="showPrevStep"></div>
            <div class="arrow-right" @click="showNextStep"></div>
            <div class="steps" :style="{'margin-left': 'calc(-' + (step - 1) + ' * 100vw)'}">
                <div class="step"><h4>1. Create an account</h4><p>Register at the site and login</p></div>
                <div class="step"><h4>2. Purchase Climate CRON</h4><p>Buy desired amount of Climate CRON</p></div>
                <div class="step"><h4>3. Ensure water security</h4><p>Spend units for climate services or speculate</p></div>
            </div>
        </div>      

        <div class="claim__cron" v-if="isMainPage">
            <div class="claim__cron-wrapper">
                <router-link :to="{ name: 'register'}" class="register" v-if="!$store.state.user.status">Create account</router-link>
                <router-link :to="{ name: 'personal'}" class="register" v-if="$store.state.user.status">My account</router-link>
            </div>
        </div>
        
        <div class="pdf" v-if="isMainPage">            
            <div class="pdf__column">                
                <div>
                    <div class="pdf__profile">
                        <div>
                            <h2><router-link :to="{ name: 'concept'}">The Concept of Monetization and Marketing of Global Market of Water Resources by means of “CLIMATE CRON”</router-link></h2>
                        </div>
                    </div>                    
                </div>  
            </div>         
        </div>  
    </div>    
</template>
<script>
import EnlargeableImage from '@diracleo/vue-enlargeable-image';
import { VueAgile } from 'vue-agile'
// import charts from './charts'
import ArticleWidget from '../../pages/main/ArticleWidget.vue';
export default {
    data() {
        return {
            isHowToShifted: true,
            step: 1,
            isDesktop: false,
            // chartData: {
			// 	datasets: [{
			// 		data: [
			// 			200000,
			// 			5000,
			// 			14000,
			// 			6000
			// 		],
			// 		backgroundColor: [
			// 			"#FF6583",
			// 			"#FDD057",
			// 			"#4ABFC0",
			// 			"#419FEB",
			// 		],
			// 		label: 'Dataset 1'
			// 	}],
			// 	labels: [
            //         'The Government of Sindh (Pakistan)',
			// 		'"NBB TC Holding"',
			// 		'"Shehzaib Mining Company"',
			// 		'"572 Environmental Consultants"'
			// 		// { text: 'The Government of Sindh (Pakistan)', link: '/articles/government-of-sindh-pakistan-purchased-200-000-climate-cron'},
			// 		// { text: '"NBB TC Holding"', link: '/news/nbb-tc-holding-purchased-5000-climate-cron'},
			// 		// { text: '"Shehzaib Mining Company"', link: '/news/comapny-shehzaib-mining-company-purchased-14000-climate-crons'},
			// 		// { text: '"572 Environmental Consultants"', link: '/news/comapny-572-environmental-consultants-purchased-6000-climate-crons'}
			// 	]
            // },
            // chartOptions: {
            //     responsive: true,
			// 	legend: {
			// 		position: 'bottom',
			// 	},
			// 	title: {
			// 		display: true
			// 	},
			// 	animation: {
			// 		animateScale: true,
			// 		animateRotate: true
			// 	}
            // }
        }
    },
    components: {
		agile: VueAgile,
        EnlargeableImage,
        // charts,
        'article-widget': ArticleWidget
    },
    computed: {
        chartsHeight() {
            return 400
        }
    },
    props: ['isMainPage'],
    methods: {
        checkScroll: function() {            
            if (this.$el.querySelector(".how-to-start") && this.isHowToShifted) {
                if (this.$el.querySelector(".how-to-start").getBoundingClientRect().top < window.innerHeight - this.$el.querySelector(".how-to-start").getBoundingClientRect().height / 3) {
                    this.isHowToShifted = false;
                }    
            }
        },
        showPrevStep: function() {
            this.step = this.step - 1 != 0 ? this.step - 1 : 3;
        },
        showNextStep: function() {
            this.step = this.step + 1 != 4 ? this.step + 1 : 1;
        }
    },
    mounted(){
        this.$store.watch(
            state => this.checkScroll(state.scrollTop)
        );
    }
}
</script>